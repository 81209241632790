
export const customStylesPais = {
    placeholder: (provided,) => ({
      ...provided,
      color: "#c7c7c7",
      marginLeft: 6,
      marginRight: 0,
      textAlign: "left",
    }),
    control: provided => ({
      ...provided,
      width: "100%",
      borderRadius: 8,
      margin:"11px 0px 0",
      minHeight: 51,
    }),
  }


  export const customStyles = {
    option: (provided,) => ({
      ...provided,
      width: 150,
      paddingTop: 10,
    }),
    menu: (provided,) => ({
      ...provided,
      width: 150,
      marginTop: 0,
    }),
    control: provided => ({
      ...provided,
      width: "100%",
      borderRadius: 8,
      marginTop: 11,
      minHeight: 50,
    }),
  }