import React, { useState } from "react"
import ViewOne from "./viewOne"
import ViewTwo from "./viewTwo"
import TitleUnderline from "./titleUnderline"



const Intro = ({
  onClickConfirm,
  countries,
  numberWorkers,
  numberClient,
}) => {
  const [dataOne, setDataOne] = useState(false)
  const [dataTwo, setDataTwo] = useState(false)
  const [sectionView, setSectionView] = useState(true)

  return (
    <div className="container-page-hablemos">
      <p className="container-page-hablemos-title">
      Habla con nuestro <TitleUnderline underline="equipo de ventas" /> 
      </p>


      <div>
        {sectionView ? (
          // Vista uno
          <ViewOne
            setSectionView={setSectionView}
            countries={countries}
            setDataOne={setDataOne}
            onClickConfirm={onClickConfirm}
            dataOne={dataOne}
          />
        ) : (
          // Vista dos
          <ViewTwo
            numberWorkers={numberWorkers}
            numberClient={numberClient}
            dataOne={dataOne}
            dataTwo={dataTwo}
            setDataTwo={setDataTwo}
            onClickConfirm={onClickConfirm}
            setSectionView={setSectionView}
          />
        )}
      </div>
    </div>
  )
}

export default Intro
